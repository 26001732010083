import SolearesImg from "../../../assets/images/projects/soleares"

/*


      {
        type: "text",
        title: {},
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      },

      {
        type: "content-text",
        title: {},
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      },
      
      { type: "after", size: "lg", afterImg: "kitchen1After" },

      {
        type: "comparison",
        size: "sm",
        beforeImg: "exterior1Before",
        beforeImgStyle: {},
        afterImg: "kitchen1After",
        afterStyle: {},
      },


*/
export const solearesData = [
  {
    name: "kitchen",
    ImageComponent: SolearesImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Kitchen",
        text: `The kitchen was completely separated with two walls and had no view. We put a complete new kitchen in and made kitchen living dining open plan so that the view could be admired from every angle.`,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "kitchen1Before",
        beforeImgStyle: {},
        afterImg: "kitchen1After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "dining1Before",
        beforeImgStyle: {},
        afterImg: "dining1After",
        afterStyle: {},
      },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "kitchen2After" },
      { type: "after", size: "sm", afterImg: "kitchen3After" },
    ],
  },
  {
    name: "bathrooms",
    ImageComponent: SolearesImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bathrooms",
        text: `We converted the old living room into a bedroom with an ensuite, now making the house 3 bedrooms, 2 bathrooms. `,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "bathroom2Before",
        beforeImgStyle: {},
        afterImg: "bathroom2After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "md",
        beforeImg: "bathroom1Before",
        beforeImgStyle: {},
        afterImg: "bathroom1After",
        afterStyle: {},
      },
    ],
  },
  {
    name: "bedrooms",
    ImageComponent: SolearesImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bedrooms",
        text: `There was a large unsightly  and rotten sun room off the master bedroom which we soon removed and replaced with a window, providing privacy and a view.
        To save space in the master bedroom we got creative and added in-built sidetables with outlets and a mirrored shelf above the bed.`,
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bedroom2Before",
        beforeImgStyle: {},
        afterImg: "bedroom2After",
        afterStyle: {},
      },
      { type: "after", size: "md", afterImg: "bedroom1After" },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bedroom4Before",
        beforeImgStyle: {},
        afterImg: "bedroom4After",
        afterStyle: {},
      },
      { type: "after", size: "md", afterImg: "bedroom5After" },
      {
        type: "comparison",
        size: "md",
        beforeImg: "bedroom3Before",
        beforeImgStyle: {},
        afterImg: "bedroom3After",
        afterStyle: {},
      },
    ],
  },
  {
    name: "exterior",
    ImageComponent: SolearesImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Exterior",
        text: `The cladding of this house was in great condition so it would have been a waste to replace it. 
        We replaced the old concrete tile roof with a brand new colour-steel roof.
        We also replaced all of the old aluminium windows with new double glazed ones.`,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "exterior1Before",
        beforeImgStyle: {},
        afterImg: "exterior1After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "exterior3Before",
        beforeImgStyle: {},
        afterImg: "exterior3After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "exterior2Before",
        beforeImgStyle: {},
        afterImg: "exterior2After",
        afterStyle: {},
      },

      { type: "after", size: "md", afterImg: "exterior4After" },
      {
        type: "content-text",
        text: `We strengthened the existing deck out front and replaced the old pine boards with Kwila. We also added a new deck on the northern side and finished it off with glass balustrade.`,
      },
    ],
  },
  {
    name: "lounge",
    ImageComponent: SolearesImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Lounge",
        text: `There used to be a fireplace where the feature wall is now. Therefore there was a large void that we were able to better utilise. 
        We gave the living room more space and installed large storage in the hallway with big sliding doors. We love it when we find hidden gems like this, 
        storage is an important part of a home but is often over-looked.`,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "lounge1Before",
        beforeImgStyle: {},
        afterImg: "lounge1After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "comparison",
        size: "md",
        beforeImg: "lounge2Before",
        beforeImgStyle: {},
        afterImg: "lounge2After",
        afterStyle: {},
      },
    ],
  },
]
