import { graphql } from "gatsby"

import Soleares from "../components/projects/soleares"

export default Soleares

export const query = graphql`
  query {
    bathroom1After: file(
      relativePath: { eq: "projects/soleares/bathroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom1Before: file(
      relativePath: { eq: "projects/soleares/bathroom1Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bathroom2After: file(
      relativePath: { eq: "projects/soleares/bathroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bathroom2Before: file(
      relativePath: { eq: "projects/soleares/bathroom2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom3After: file(
      relativePath: { eq: "projects/soleares/bathroom3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom1After: file(
      relativePath: { eq: "projects/soleares/bedroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom2After: file(
      relativePath: { eq: "projects/soleares/bedroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom2Before: file(
      relativePath: { eq: "projects/soleares/bedroom2Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom3After: file(
      relativePath: { eq: "projects/soleares/bedroom3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom3Before: file(
      relativePath: { eq: "projects/soleares/bedroom3Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom4After: file(
      relativePath: { eq: "projects/soleares/bedroom4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom4Before: file(
      relativePath: { eq: "projects/soleares/bedroom4Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom5After: file(
      relativePath: { eq: "projects/soleares/bedroom5After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    dining1After: file(
      relativePath: { eq: "projects/soleares/dining1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    dining1Before: file(
      relativePath: { eq: "projects/soleares/dining1Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    dining2After: file(
      relativePath: { eq: "projects/soleares/dining2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior1After: file(
      relativePath: { eq: "projects/soleares/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior1Before: file(
      relativePath: { eq: "projects/soleares/exterior1Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior2After: file(
      relativePath: { eq: "projects/soleares/exterior2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior2Before: file(
      relativePath: { eq: "projects/soleares/exterior2Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior3After: file(
      relativePath: { eq: "projects/soleares/exterior3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior3Before: file(
      relativePath: { eq: "projects/soleares/exterior3Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior4After: file(
      relativePath: { eq: "projects/soleares/exterior4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kitchen1After: file(
      relativePath: { eq: "projects/soleares/kitchen1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kitchen1Before: file(
      relativePath: { eq: "projects/soleares/kitchen1Before.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kitchen2After: file(
      relativePath: { eq: "projects/soleares/kitchen2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kitchen3After: file(
      relativePath: { eq: "projects/soleares/kitchen3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kitchen4After: file(
      relativePath: { eq: "projects/soleares/kitchen4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge1After: file(
      relativePath: { eq: "projects/soleares/lounge1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge1Before: file(
      relativePath: { eq: "projects/soleares/lounge1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge2After: file(
      relativePath: { eq: "projects/soleares/lounge2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge2Before: file(
      relativePath: { eq: "projects/soleares/lounge2Before.jpeg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
