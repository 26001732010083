import React from "react"

import "../global-project-styles.scss"

import useExitAnimationFade from "../../../animations/useExitAnimationFade"

import ProjectHero from "../ProjectHero"
import useProjectImagesParallax from "../../../animations/useParallaxItems"
import useProjectRevealAnimations from "../../../animations/useProjectRevealAnimations"
import RepeatableSection from "../RepeatableSection"

import { solearesData } from "./solearesData"
import SolearesImg from "../../../assets/images/projects/soleares"
import ProjectEndContent from "../ProjectEndContent"
import SEO from "../../seo"

const Soleares = ({ data: pageData }) => {
  useExitAnimationFade()
  useProjectImagesParallax()
  useProjectRevealAnimations()

  console.log("pageData", pageData)

  return (
    <div className="page project soleares">
      <SEO title="Soleares" />

      <ProjectHero
        pageData={pageData}
        heroImg="exterior1After"
        title="soleares"
        description="A sleak and stylish home with panoramic views of the Canterbury plains."
      />

      {solearesData.map((sectionData, index) => (
        <RepeatableSection
          pageData={pageData}
          key={index}
          section={sectionData}
        />
      ))}

      <ProjectEndContent to="/other-projects/" />
    </div>
  )
}

export default Soleares
